<template>
  <Layout>
    <div id="printheader" class="container-fluid mb-4 d-none">
      <div class="py-4 bg-primary justify-content-around text-white row">
        <div class="col align-content-center" @click="$router.push({ name: 'home' })">
          <h1 class="application-logo">Outcomms</h1>
          <span class="ms-3">Outcomms</span>
        </div>
        <div class="pe-3 text-right text-light">
          Summary created by {{ user.name }}.<br />All content strictly private and confidential.
        </div>
      </div>
    </div>

    <!-- Loading spinner -->
    <div
      v-if="$apollo.loading"
      class="position-fixed end-0 w-100 justify-content-center"
      style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
    >
      <div class="text-center col pt-5 mt-5">
        <BaseSpinner />
        <p class="d-block lead fs-4 mt-5">The timeline is loading</p>
      </div>
    </div>

    <div class="container-fluid">
      <div id="calendartopbar" class="row">
        <div class="col-4 col-md-3 col-lg-6 col-xl-6 col-xxl-7 align-items-center d-flex">
          <a class="link-orange" @click="showFilterPanel">
            <svg
              viewBox="0 0 100 100"
              width="15"
              height="15"
              fill="#e26c49"
              class="link-orange"
              style="margin-right: 15px"
            >
              <rect width="100" height="20"></rect>
              <rect y="30" width="100" height="20"></rect>
              <rect y="60" width="100" height="20"></rect>
            </svg>
            Show filters
          </a>
        </div>
        <div class="col-8 col-md-7 col-lg-4 col-xl-4 col-xxl-3 text-end">
          <form class="form-inline justify-content-end" @submit.prevent="filterTitle">
            <BaseInput
              v-model="filter.brief.title.contains"
              placeholder="Brief title..."
              type="text"
              name="search"
              icon-classes="text-muted"
              :icon="['fas', 'search']"
              @input="debouncedFilterTitle"
              @keyup="debouncedFilterTitle"
            />
          </form>
        </div>
        <div class="col-12 col-md-2 col-xl-2 col-xxl-2 text-end">
          <a
            v-if="user.isTeamUser"
            class="btn btn-success text-center"
            role="button"
            @click="$router.push({ name: 'briefs.new' })"
            >Create new</a
          >
        </div>
      </div>

      <hr class="dotted-spacer" />

      <div class="row justify-content-start">
        <div class="col">
          <nav class="navbar navbar-light bg-light navbar-expand-sm pb-0 pt-3">
            <div class="nav nav-tabs" role="tablist">
              <a
                class="nav-link font-display"
                :class="{ active: currentView === 'day' }"
                role="tab"
                @click="switchView('day')"
                >Day</a
              >
              <a
                class="nav-link font-display"
                :class="{ active: currentView === 'week' }"
                role="tab"
                @click="switchView('week')"
                >Week</a
              >
              <a
                class="nav-link font-display"
                :class="{ active: currentView === 'month' }"
                role="tab"
                @click="switchView('month')"
                >Month</a
              >
              <a
                class="nav-link font-display"
                :class="{ active: currentView === 'quarter' }"
                role="tab"
                @click="switchView('quarter')"
                >Quarter</a
              >
              <a
                class="nav-link font-display"
                :class="{ active: currentView === 'sixMonths' }"
                role="tab"
                @click="switchView('sixMonths')"
                >6 Months</a
              >
            </div>

            <div class="text-end ms-auto d-flex mb-1 text-center align-content-center" style="flex-direction: row">
              <!-- <span class="me-2 ms-5 text-dark align-self-center">View</span>
              <BaseMultiselect
                v-model="view"
                :options="viewOptions"
                :default-single-view="true"
                :multiple="false"
                track-by="id"
                name="view"
              /> -->
              <!--
              <span class="me-2 ms-5 text-dark align-self-center">Organise by</span>
              <BaseMultiselect
                v-model="activitiesGroupBy"
                :options="activitiesGroupOptions"
                :default-single-view="true"
                :multiple="false"
                :allow-empty="false"
                placeholder="All"
              />-->
            </div>
          </nav>

          <div class="row align-items-center mt-2 justify-content-start">
            <div class="col"></div>
            <div class="col text-center">
              <div v-if="currentView === 'day'" class="d-inline-flex items-center ml-2">
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info me-3"
                  aria-label="prev"
                  @click="goToPreviousDay"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-left']" />
                </button>
                <BaseDatepicker
                  v-model="datepickerDate"
                  :display-value="datepickerDisplayValue.day"
                  :options="{ singleDatePicker: true, timeline: true }"
                  class="timeline cursor-pointer"
                  @input="datepickerGo('day')"
                />
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info ms-3"
                  aria-label="next"
                  @click="goToNextDay"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-right']" />
                </button>
              </div>
              <div v-if="currentView === 'week'" class="d-inline-flex items-center ml-2">
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info me-3"
                  aria-label="prev"
                  @click="goToPreviousWeek"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-left']" />
                </button>
                <BaseDatepicker
                  v-model="datepickerDate"
                  :display-value="datepickerDisplayValue.week"
                  :options="{ singleDatePicker: true, timeline: true }"
                  class="timeline cursor-pointer"
                  @input="datepickerGo('week')"
                />
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info ms-3"
                  aria-label="next"
                  @click="goToNextWeek"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-right']" />
                </button>
              </div>
              <div v-if="currentView === 'month'" class="d-inline-flex items-center ml-2">
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info me-3"
                  aria-label="prev"
                  @click="goToPreviousMonth"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-left']" />
                </button>
                <BaseDatepicker
                  v-model="datepickerDate"
                  :display-value="datepickerDisplayValue.month"
                  :options="{ singleDatePicker: true, timeline: true }"
                  class="timeline cursor-pointer"
                  @input="datepickerGo('month')"
                />
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info ms-3"
                  aria-label="next"
                  @click="goToNextMonth"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-right']" />
                </button>
              </div>
              <div v-if="currentView === 'quarter'" class="d-inline-flex items-center ml-2">
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info me-3"
                  aria-label="prev"
                  @click="goToPreviousQuarter"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-left']" />
                </button>
                <select name="month" class="cursor-pointer" @input="datepickerGo('quarter', $event)">
                  <option v-if="!monthListValues.includes(currentQuarter.start.value)" :selected="true"></option>
                  <option
                    v-for="month in monthListQuarter"
                    :key="month.value"
                    :value="month.value"
                    :selected="month.value === currentQuarter.start.value"
                  >
                    {{ month.name }}
                  </option>
                </select>
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info ms-3"
                  aria-label="next"
                  @click="goToNextQuarter"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-right']" />
                </button>
              </div>
              <div v-if="currentView === 'sixMonths'" class="d-inline-flex items-center ml-2">
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info me-3"
                  aria-label="prev"
                  @click="goToPreviousSixMonths"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-left']" />
                </button>
                <select name="month" class="cursor-pointer" @input="datepickerGo('sixMonths', $event)">
                  <option v-if="!monthListValues.includes(currentSixMonths.start.value)" :selected="true">
                    {{ currentSixMonths.start.name }} - {{ currentSixMonths.end.name }}
                  </option>
                  <option
                    v-for="month in monthListSixMonths"
                    :key="month.value"
                    :value="month.value"
                    :selected="month.value === currentSixMonths.start.value"
                  >
                    {{ month.name }}
                  </option>
                </select>
                <button
                  type="button"
                  class="btn btn-outline-info btn-sm text-info ms-3"
                  aria-label="next"
                  @click="goToNextSixMonths"
                >
                  <BaseIcon class="text-lg" :name="['fas', 'chevron-right']" />
                </button>
              </div>
            </div>
            <div class="col text-end">
              <BaseButton type="button" class="text-info text-end" @click="gotoToday">Go to today</BaseButton>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col mt-2">
          <!-- <FullCalendar ref="fullCalendar" :options="calendarOptions" /> -->
          <BarChart :options="chartOptions" :chart-data="chartData" :height="125" />
        </div>
      </div>

      <!-- <div class="col col-lg-3 col-xl-2">
          <span v-if="activities" class="text-muted">Activities in view: {{ activities.length }}</span>
          <BaseButton id="printButton" class="inline text-muted" @click.prevent="printPage">
            <BaseIcon :name="['fas', 'print']" />
            Print this calendar
          </BaseButton>
        </div> -->

      <slideout-panel><FilterPanel></FilterPanel></slideout-panel>
    </div>
  </Layout>
</template>

<script>
import appConfig from "@src/app.config";
import Layout from "@layouts/main";

import FilterPanel from "@components/calendar/filter-panel";

import BarChart from "@components/calendar/timeline-bar";

// import contrastingColour from "@utils/color";

import { LocalGetSelf } from "@gql/user";
import { GetActivitiesByCompanyAndDates } from "@gql/activity";
import { GetChannels } from "@gql/tag";
import { GetReminders } from "@gql/reminder";
import { getActivityAssignmentGroups } from "@gql/group";

import { range, flatMap, debounce, orderBy } from "lodash";
import {
  format,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  addMonths,
  startOfYear,
  parseISO,
  formatISO,
  getDaysInMonth,
  // getISOWeeksInYear,
  getDay,
  differenceInWeeks,
  addDays,
  subDays,
  addWeeks,
  subWeeks,
  isAfter,
  isBefore,
  isEqual,
  startOfQuarter,
  endOfQuarter,
  // getISOWeekYear,
} from "date-fns";

import * as locale from "date-fns/locale/en-AU";

const dateFormats = {
  day: "MMM dd, yyyy",
  week: "'Week' w',' MMM, yyyy",
  month: "MMM, yyyy",
  quarter: "MMM, yyyy",
  sixMonths: "MMM, yyyy",
};

export default {
  name: "Timeline",
  page: {
    title: "Timeline",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    FilterPanel,
    BarChart,
  },
  data() {
    return {
      filter: {
        brief: {
          title: { contains: "", mode: "insensitive" },
        },
        // startDate: {
        //   gte: format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
        //   lte: format(endOfMonth(addMonths(new Date(), 1)), "yyyy-MM-dd"),
        // },
      },
      // activitiesGroupOptions: [
      //   { label: "Channel", id: "channels" },
      //   { label: "Status", id: "activityStatuses" },
      //   { label: "Campaign", id: "programs" },
      //   { label: "Spokesperson", id: "spokespersons" },
      //   { label: "Team member", id: "teamMembers" },
      //   { label: "Tags", id: "tags" },
      //   { label: "Groups", id: "groups" },
      //   { label: "Locations", id: "locations" },
      // ],
      // activitiesGroupBy: { label: "Channel", id: "channels" },
      view: { label: "Timeline", id: "timeline" },

      datepickerDate: null,
      currentView: "month",
      calendarResources: [],
      organizedActivities: null,

      debouncedFilterTitle: null,
      tooltipHideDelay: 300,
      tooltipLoading: false,
      debounced: null, // the hide tooltip function. It's here so we can canel it in case we highlight something else
      showMonthDropdown: false,
      filterPanelValues: null,
      tooltipShown: false,
      tooltipLeft: 0,
      tooltipTop: 0,
      activityInTooltip: {},
      filterPanelInstance: null, // the filter panel instance (to keep it in the dom when closed)
      isFilterDefault: 0,

      datepickerDisplayValue: {
        day: format(startOfDay(new Date()), dateFormats.day),
        week:
          format(startOfWeek(new Date(), { locale }), dateFormats.day) +
          " - " +
          format(endOfWeek(new Date(), { locale }), dateFormats.day),
        month:
          format(startOfMonth(new Date()), dateFormats.day) + " - " + format(endOfMonth(new Date()), dateFormats.day),
        quarter:
          format(startOfMonth(new Date()), dateFormats.month) +
          " - " +
          format(endOfMonth(addMonths(new Date(), 2)), dateFormats.month),
        sixMonths:
          format(startOfMonth(new Date()), dateFormats.month) +
          " - " +
          format(endOfMonth(addMonths(new Date(), 5)), dateFormats.month),
      },
      dateFormats: dateFormats,
      currentMonth: {
        start: {
          raw: startOfMonth(new Date()),
          name: format(startOfMonth(new Date()), "MMM, yyyy"),
          value: format(startOfMonth(new Date()), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(new Date()),
          name: format(endOfMonth(new Date()), "MMM, yyyy"),
          value: format(endOfMonth(new Date()), "yyyy-MM-dd"),
        },
      },
      currentQuarter: {
        start: {
          raw: startOfMonth(new Date()),
          name: format(startOfQuarter(new Date()), "MMM, yyyy"),
          value: format(startOfQuarter(new Date()), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfQuarter(new Date()),
          name: format(endOfQuarter(new Date()), "MMM, yyyy"),
          value: format(endOfQuarter(new Date()), "yyyy-MM-dd"),
        },
      },
      currentSixMonths: {
        start: {
          raw:
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
            isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? startOfQuarter(new Date())
              : startOfQuarter(subMonths(new Date(), 3)),
          name: format(
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
              isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? startOfQuarter(new Date())
              : startOfQuarter(subMonths(new Date(), 3)),
            "MMM, yyyy"
          ),
          value: format(
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
              isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? startOfQuarter(new Date())
              : startOfQuarter(subMonths(new Date(), 3)),
            "yyyy-MM-dd"
          ),
        },
        end: {
          raw:
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
            isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? endOfQuarter(addMonths(new Date(), 3))
              : endOfQuarter(new Date()),
          name: format(
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
              isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? endOfQuarter(addMonths(new Date(), 3))
              : endOfQuarter(new Date()),
            "MMM, yyyy"
          ),
          value: format(
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
              isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? endOfQuarter(addMonths(new Date(), 3))
              : endOfQuarter(new Date()),
            "yyyy-MM-dd"
          ),
        },
      },
      currentWeek: {
        start: {
          raw: startOfWeek(new Date(), { locale }),
          name: format(startOfWeek(new Date(), { locale }), dateFormats.week),
          value: format(startOfWeek(new Date(), { locale }), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfWeek(new Date(), { locale }),
          name: format(endOfWeek(new Date(), { locale }), dateFormats.week),
          value: format(endOfWeek(new Date(), { locale }), "yyyy-MM-dd"),
        },
      },
      currentDay: {
        start: {
          raw: startOfDay(new Date()),
          name: format(startOfDay(new Date()), dateFormats.day),
          value: format(startOfDay(new Date()), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfDay(new Date()),
          name: format(endOfDay(new Date()), dateFormats.day),
          value: format(endOfDay(new Date()), "yyyy-MM-dd"),
        },
      },
    };
  },
  computed: {
    filterStartDate() {
      let startDate = {};
      // These all over estimate to include long-running activities
      if (this.currentView === "day") {
        startDate = {
          gte: formatISO(startOfDay(subMonths(this.currentDay.start.raw, 1))),
          lte: formatISO(endOfDay(addMonths(this.currentDay.end.raw, 1))),
        };
      } else if (this.currentView === "week") {
        startDate = {
          gte: formatISO(startOfWeek(subMonths(this.currentWeek.start.raw, 1), { locale })),
          lte: formatISO(endOfWeek(addMonths(this.currentWeek.end.raw, 1), { locale })),
        };
      } else if (this.currentView === "month") {
        startDate = {
          gte: formatISO(startOfMonth(subMonths(this.currentMonth.start.raw, 1))),
          lte: formatISO(endOfMonth(addMonths(this.currentMonth.end.raw, 1))),
        };
      } else if (this.currentView === "quarter") {
        startDate = {
          gte: formatISO(startOfMonth(subMonths(this.currentQuarter.start.raw, 1))),
          lte: formatISO(endOfMonth(addMonths(this.currentQuarter.end.raw, 1))),
        };
      } else if (this.currentView === "sixMonths") {
        startDate = {
          gte: formatISO(startOfMonth(subMonths(this.currentSixMonths.start.raw, 1))),
          lte: formatISO(endOfMonth(addMonths(this.currentSixMonths.end.raw, 1))),
        };
      }
      return startDate;
    },
    chartData() {
      if (!this.channels || !this.activities) {
        return {
          labels: [],
          datasets: [],
        };
      }
      let labels = [];
      if (this.currentView === "day") {
        labels = [this.currentDay.start.name];
      } else if (this.currentView === "week") {
        labels = range(7).map((index) =>
          format(addDays(startOfWeek(this.currentWeek.start.raw, { locale }), index), this.dateFormats.day)
        );
      } else if (this.currentView === "month") {
        labels = range(getDaysInMonth(this.currentMonth.start.raw)).map((index) =>
          format(addDays(startOfMonth(this.currentMonth.start.raw), index), this.dateFormats.day)
        );
      } else if (this.currentView === "quarter") {
        labels = [];
        for (
          let currDate = this.currentQuarter.start.raw;
          isBefore(currDate, this.currentQuarter.end.raw);
          currDate = addWeeks(currDate, 1)
        ) {
          labels.push(format(currDate, this.dateFormats.week));
        }
      } else if (this.currentView === "sixMonths") {
        labels = [];
        for (
          let currDate = this.currentSixMonths.start.raw;
          isBefore(currDate, this.currentSixMonths.end.raw);
          currDate = addWeeks(currDate, 1)
        ) {
          labels.push(format(currDate, this.dateFormats.week));
        }
      }

      const channels = orderBy(
        this.channels
          // .filter((c) => !c.hiddenInFilters)
          .map((channel) => ({
            label: channel.label,
            id: channel.id,
            data: new Array(labels.length).fill(0),
            backgroundColor: channel.color,
          })),
        ["label"]
      );

      this.activities.forEach((activity) => {
        const channelIndex = channels.findIndex((channel) =>
          activity.channel ? channel.id === activity.channel.id : false
        );
        if (channelIndex === -1) {
          return;
        }
        if (this.currentView === "day") {
          this.$log.debug("Activity day: ", activity);
          if (
            (isBefore(parseISO(activity.startDate), this.currentDay.end.raw) ||
              isEqual(parseISO(activity.startDate), this.currentDay.end.raw)) &&
            (isAfter(parseISO(activity.endDate), this.currentDay.start.raw) ||
              isEqual(parseISO(activity.endDate), this.currentDay.start.raw))
          ) {
            channels[channelIndex].data[0] += 1;
          }
        } else if (this.currentView === "week") {
          if (
            (isBefore(parseISO(activity.startDate), this.currentWeek.end.raw) ||
              isEqual(parseISO(activity.startDate), this.currentWeek.end.raw)) &&
            (isAfter(parseISO(activity.endDate), this.currentWeek.start.raw) ||
              isEqual(parseISO(activity.endDate), this.currentWeek.start.raw))
          ) {
            let startPoint = this.currentWeek.start.raw;
            if (isAfter(parseISO(activity.startDate), startPoint)) {
              startPoint = parseISO(activity.startDate);
            }
            for (
              let currDate = startPoint;
              (isBefore(currDate, parseISO(activity.endDate)) || isEqual(currDate, parseISO(activity.endDate))) &&
              (isBefore(currDate, this.currentWeek.end.raw) || isEqual(currDate, this.currentWeek.end.raw));
              currDate = addDays(currDate, 1)
            ) {
              channels[channelIndex].data[getDay(currDate) - 1] += 1;
            }
          }
        } else if (this.currentView === "month") {
          // this.$log.debug(
          //   isBefore(parseISO(activity.startDate), this.currentMonth.end.raw),
          //   activity.startDate,
          //   this.currentMonth.end.raw
          // );
          if (
            (isBefore(parseISO(activity.startDate), this.currentMonth.end.raw) ||
              isEqual(parseISO(activity.startDate), this.currentMonth.end.raw)) &&
            (isAfter(parseISO(activity.endDate), this.currentMonth.start.raw) ||
              isEqual(parseISO(activity.endDate), this.currentMonth.start.raw))
          ) {
            let startPoint = this.currentMonth.start.raw;
            if (isAfter(parseISO(activity.startDate), startPoint)) {
              startPoint = parseISO(activity.startDate);
            }
            for (
              let currDate = startPoint;
              (isBefore(currDate, parseISO(activity.endDate)) || isEqual(currDate, parseISO(activity.endDate))) &&
              (isBefore(currDate, this.currentMonth.end.raw) || isEqual(currDate, this.currentMonth.end.raw));
              currDate = addDays(currDate, 1)
            ) {
              channels[channelIndex].data[format(currDate, "d") - 1] += 1;
            }
          }
        } else if (this.currentView === "quarter") {
          if (
            (isBefore(parseISO(activity.startDate), this.currentQuarter.end.raw) ||
              isEqual(parseISO(activity.startDate), this.currentQuarter.end.raw)) &&
            (isAfter(parseISO(activity.endDate), this.currentQuarter.start.raw) ||
              isEqual(parseISO(activity.endDate), this.currentQuarter.start.raw))
          ) {
            let startPoint = this.currentQuarter.start.raw;
            if (isAfter(parseISO(activity.startDate), startPoint)) {
              startPoint = parseISO(activity.startDate);
            }
            for (
              let currDate = startPoint;
              (isBefore(currDate, endOfWeek(parseISO(activity.endDate), { locale })) ||
                isEqual(currDate, endOfWeek(parseISO(activity.endDate), { locale }))) &&
              (isBefore(currDate, this.currentQuarter.end.raw) || isEqual(currDate, this.currentQuarter.end.raw));
              currDate = addWeeks(currDate, 1)
            ) {
              channels[channelIndex].data[
                differenceInWeeks(currDate, startOfWeek(this.currentQuarter.start.raw, { locale }))
              ] += 1;
            }
          }
        } else {
          // if (this.currentView === "sixMonths")
          if (
            (isBefore(parseISO(activity.startDate), this.currentSixMonths.end.raw) ||
              isEqual(parseISO(activity.startDate), this.currentSixMonths.end.raw)) &&
            (isAfter(parseISO(activity.endDate), this.currentSixMonths.start.raw) ||
              isEqual(parseISO(activity.endDate), this.currentSixMonths.start.raw))
          ) {
            let startPoint = this.currentSixMonths.start.raw;
            if (isAfter(parseISO(activity.startDate), startPoint)) {
              startPoint = parseISO(activity.startDate);
            }
            for (
              let currDate = startPoint;
              (isBefore(currDate, endOfWeek(parseISO(activity.endDate), { locale })) ||
                isEqual(currDate, endOfWeek(parseISO(activity.endDate), { locale }))) &&
              (isBefore(currDate, this.currentSixMonths.end.raw) ||
                isEqual(currDate, startOfWeek(this.currentQuarter.start.raw, { locale })));
              currDate = addWeeks(currDate, 1)
            ) {
              channels[channelIndex].data[differenceInWeeks(currDate, this.currentSixMonths.start.raw)] += 1;
            }
          }
        }
      });
      this.$log.debug("Loading chart data: ", {
        labels: labels,
        datasets: channels,
      });
      return {
        labels: labels,
        datasets: channels,
      };
    },
    chartOptions() {
      return {
        legend: {
          position: "right",
        },
        tooltips: {
          titleFontFamily:
            "'Muli', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          bodyFontFamily:
            "'Muli', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          cornerRadius: 0,
          position: "nearest",
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
            },
          ],
          xAxes: [{ stacked: true }],
        },
      };
    },

    viewOptions() {
      const options = [
        { label: "Bar Chart", id: "chart" },
        // { label: "Timeline", id: "timeline" },
        // { label: "Bubble", id: "bubble" },
      ];
      return options;
    },
    monthList() {
      return range(12).map((index) => ({
        raw: addMonths(startOfYear(new Date()), index),
        name: format(addMonths(startOfYear(new Date()), index), "MMMM yyyy"),
        value: format(addMonths(startOfYear(new Date()), index), "yyyy-MM-dd"),
      }));
    },
    monthListSixMonths() {
      return range(12).map((index) => ({
        raw: addMonths(startOfYear(new Date()), index),
        name: format(addMonths(startOfYear(new Date()), index), "MMMM yyyy"),
        value: format(addMonths(startOfYear(new Date()), index), "yyyy-MM-dd"),
      }));
    },
    monthListQuarter() {
      return range(12).map((index) => ({
        raw: addMonths(startOfYear(new Date()), index),
        name: format(addMonths(startOfYear(new Date()), index), "MMMM yyyy"),
        value: format(addMonths(startOfYear(new Date()), index), "yyyy-MM-dd"),
      }));
    },
    monthListValues() {
      return flatMap(this.monthList, "value");
    },
    stickyFilters() {
      return Number.parseInt(process.env.VUE_APP_CALENDAR_STICKY_FILTERS) === 1;
    },
    legendChannels() {
      return this.channels ? this.channels.filter((c) => c.hiddenInFilters) : [];
    },
    isCalendarEditable() {
      return this.user ? !this.user.isViewer : false;
    },
    scrollTop() {
      return 128 - document.body.scrollTop > 0 ? 128 - document.body.scrollTop : 0;
    },
  },
  apollo: {
    user: {
      query: LocalGetSelf,
      update: (data) => data.user,
    },
    activities: {
      query: GetActivitiesByCompanyAndDates,
      fetchPolicy: "no-cache",
      variables() {
        this.$log.debug("Fetching activities with: ", {
          where: {
            ...this.formatDatesForApi({ ...this.filter, startDate: this.filterStartDate }),
            company: {
              id: { equals: this.user.company.id },
            },
          },
        });
        return {
          where: {
            ...this.formatDatesForApi({ ...this.filter, startDate: this.filterStartDate }),
            company: {
              id: { equals: this.user.company.id },
            },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got activity data from API", data);
        return data.activities;
      },
    },
    activityGroups: {
      query: getActivityAssignmentGroups,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got group data from API", data);
        return data.activityAssignmentGroups;
      },
    },
    channels: {
      query: GetChannels,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got channel data from API", data);
        return data.tags;
      },
      error(error) {
        this.$log.error("There was an error", error);
      },
    },
    reminders: {
      query: GetReminders,
      variables() {
        return {
          companyId: this.user.company.id,
          startDate: format(parseISO(this.filterStartDate.gte), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          endDate: format(parseISO(this.filterStartDate.lte), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        };
      },
      update(data) {
        this.$log.debug("Got reminder data from API", data);
        return data.reminders;
      },
      skip() {
        return !this.user;
      },
    },
  },
  watch: {
    user: {
      // This happens also on mount and ensures we don't call GetActivitiesByCompanyAndDates before we have a user
      handler(newVal) {
        // If we have filters from localstorage, apply them
        if (
          newVal &&
          newVal.company &&
          this.stickyFilters &&
          window.localStorage.getItem(process.env.VUE_APP_CALENDAR_FILTERS_LOCALSTORAGE_KEY)
        ) {
          const filters = JSON.parse(
            window.localStorage.getItem(process.env.VUE_APP_CALENDAR_FILTERS_LOCALSTORAGE_KEY)
          );
          delete filters.startDate;
          this.$log.debug("Calendar - Applying LocalStorage Filters", filters);
          this.$root.$emit("calendarFilterChange", filters);
        } else {
          this.$root.$emit("calendarFilterChange", this.filterPanelValues);
        }
      },
      deep: true,
    },
    // activitiesGroupBy: {
    //   async handler(val) {
    //     this.organizedActivities = await this.reorganiseActivities();
    //   },
    //   deep: true,
    // },
    // activities: {
    //   handler(newVal) {
    //     if (newVal === undefined || newVal === null) {
    //       this.$log.debug("UNDEFINED - Calendar activities");
    //       this.jumpToMonth(this.currentMonth);
    //     }
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.debouncedFilterTitle = debounce(this.filterTitle, 300);
  },
  mounted() {
    // Gain access to fullcalendar API object
    // https://fullcalendar.io/docs/vue - Accessing FullCalendar’s API section

    // this.calendarApi = this.$refs.fullCalendar.getApi();
    this.$root.$on("calendarFilterChange", this.onCalendarFilterChange);
    this.$root.$on("filterDefault", this.filterDefault);
    this.filterPanelInstance = this.$showPanel({
      component: FilterPanel,
      openOn: "left",
      disableBgClick: false,
      cssClass: "filterPanel",
      width: 325,
      keepAlive: true,
      props: {
        parentFilter: this.filter,
      },
    });
    this.filterPanelInstance.hide();
    // If we have dates saved in LocalStorage, jump to those
    const savedDates = null; // This feature is disabled for now
    /* const savedDates = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_CALENDAR_DATES_LOCALSTORAGE_KEY));
    if (savedDates && savedDates.start) {
      this.currentMonth = {
        raw: startOfMonth(parse(savedDates.start, "yyyy-MM-dd", new Date(2020, 0, 1))),
        name: format(startOfMonth(parse(savedDates.start, "yyyy-MM-dd", new Date(2020, 0, 1))), "MMMM yyyy"),
        value: format(startOfMonth(parse(savedDates.start, "yyyy-MM-dd", new Date(2020, 0, 1))), "yyyy-MM-dd"),
      };
      this.$log.debug("Jumping to SAVED date", savedDates, this.currentMonth);
      this.jumpToMonth(this.currentMonth);
    } */
    // Otherwise go to the current month
    this.$log.debug("Jumping CURRENT date", savedDates, this.currentMonth);
    this.jumpToMonth(this.currentMonth);
  },
  methods: {
    goToPreviousMonth() {
      const previousMonth = {
        start: {
          raw: startOfMonth(subMonths(this.currentMonth.start.raw, 1)),
          name: format(startOfMonth(subMonths(this.currentMonth.start.raw, 1)), this.dateFormats.month),
          value: format(startOfMonth(subMonths(this.currentMonth.start.raw, 1)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(subMonths(this.currentMonth.end.raw, 1)),
          name: format(endOfMonth(subMonths(this.currentMonth.end.raw, 1)), this.dateFormats.month),
          value: format(endOfMonth(subMonths(this.currentMonth.end.raw, 1)), "yyyy-MM-dd"),
        },
      };
      this.currentMonth = previousMonth;
      this.datepickerDisplayValue.quarter = `${this.currentMonth.start.name} - ${this.currentMonth.end.name}`;

      this.jumpToMonth(this.currentMonth);
    },
    goToNextMonth() {
      const nextMonth = {
        start: {
          raw: startOfMonth(addMonths(this.currentMonth.start.raw, 1)),
          name: format(startOfMonth(addMonths(this.currentMonth.start.raw, 1)), this.dateFormats.month),
          value: format(startOfMonth(addMonths(this.currentMonth.start.raw, 1)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(addMonths(this.currentMonth.end.raw, 1)),
          name: format(endOfMonth(addMonths(this.currentMonth.end.raw, 1)), this.dateFormats.month),
          value: format(endOfMonth(addMonths(this.currentMonth.end.raw, 1)), "yyyy-MM-dd"),
        },
      };
      this.currentMonth = nextMonth;
      this.datepickerDisplayValue.quarter = `${this.currentMonth.start.name} - ${this.currentMonth.end.name}`;

      this.jumpToMonth(this.currentMonth);
    },
    goToPreviousQuarter() {
      // calculate previous quarter
      const previousQuarter = {
        start: {
          raw: startOfMonth(subMonths(this.currentQuarter.start.raw, 1)),
          name: format(startOfMonth(subMonths(this.currentQuarter.start.raw, 1)), this.dateFormats.quarter),
          value: format(startOfMonth(subMonths(this.currentQuarter.start.raw, 1)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(subMonths(this.currentQuarter.end.raw, 1)),
          name: format(endOfMonth(subMonths(this.currentQuarter.end.raw, 1)), this.dateFormats.quarter),
          value: format(endOfMonth(subMonths(this.currentQuarter.end.raw, 1)), "yyyy-MM-dd"),
        },
      };
      this.currentQuarter = previousQuarter;
      this.datepickerDisplayValue.quarter = `${this.currentQuarter.start.name} - ${this.currentQuarter.end.name}`;

      this.jumpToQuarter(this.currentQuarter);
    },
    goToNextQuarter() {
      // calculate next quarter
      const nextQuarter = {
        start: {
          raw: startOfMonth(addMonths(this.currentQuarter.start.raw, 1)),
          name: format(startOfMonth(addMonths(this.currentQuarter.start.raw, 1)), this.dateFormats.quarter),
          value: format(startOfMonth(addMonths(this.currentQuarter.start.raw, 1)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(addMonths(this.currentQuarter.end.raw, 1)),
          name: format(endOfMonth(addMonths(this.currentQuarter.end.raw, 1)), this.dateFormats.quarter),
          value: format(endOfMonth(addMonths(this.currentQuarter.end.raw, 1)), "yyyy-MM-dd"),
        },
      };
      this.currentQuarter = nextQuarter;
      this.datepickerDisplayValue.quarter = `${this.currentQuarter.start.name} - ${this.currentQuarter.end.name}`;

      this.jumpToQuarter(this.currentQuarter);
    },
    goToPreviousSixMonths() {
      // calculate previous quarter
      const previousSixMonths = {
        start: {
          raw: startOfMonth(subMonths(this.currentSixMonths.start.raw, 1)),
          name: format(startOfMonth(subMonths(this.currentSixMonths.start.raw, 1)), this.dateFormats.sixMonths),
          value: format(startOfMonth(subMonths(this.currentSixMonths.start.raw, 1)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(subMonths(this.currentSixMonths.end.raw, 1)),
          name: format(endOfMonth(subMonths(this.currentSixMonths.end.raw, 1)), this.dateFormats.sixMonths),
          value: format(endOfMonth(subMonths(this.currentSixMonths.end.raw, 1)), "yyyy-MM-dd"),
        },
      };
      this.currentSixMonths = previousSixMonths;
      this.datepickerDisplayValue.sixMonths = `${this.currentSixMonths.start.name} - ${this.currentSixMonths.end.name}`;

      this.jumpToSixMonths(this.currentSixMonths);
    },
    goToNextSixMonths() {
      // calculate next quarter
      const nextSixMonths = {
        start: {
          raw: startOfMonth(addMonths(this.currentSixMonths.start.raw, 1)),
          name: format(startOfMonth(addMonths(this.currentSixMonths.start.raw, 1)), this.dateFormats.sixMonths),
          value: format(startOfMonth(addMonths(this.currentSixMonths.start.raw, 1)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(addMonths(this.currentSixMonths.end.raw, 1)),
          name: format(endOfMonth(addMonths(this.currentSixMonths.end.raw, 1)), this.dateFormats.sixMonths),
          value: format(endOfMonth(addMonths(this.currentSixMonths.end.raw, 1)), "yyyy-MM-dd"),
        },
      };
      this.currentSixMonths = nextSixMonths;
      this.datepickerDisplayValue.sixMonths = `${this.currentSixMonths.start.name} - ${this.currentSixMonths.end.name}`;

      this.jumpToSixMonths(this.currentSixMonths);
    },
    goToPreviousWeek() {
      // calculate previous quarter
      const previousWeek = {
        start: {
          raw: startOfWeek(subWeeks(this.currentWeek.start.raw, 1), { locale }),
          name: format(startOfWeek(subWeeks(this.currentWeek.start.raw, 1), { locale }), this.dateFormats.week),
          value: format(startOfWeek(subWeeks(this.currentWeek.start.raw, 1), { locale }), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfWeek(subWeeks(this.currentWeek.end.raw, 1), { locale }),
          name: format(endOfWeek(subWeeks(this.currentWeek.end.raw, 1), { locale }), this.dateFormats.week),
          value: format(endOfWeek(subWeeks(this.currentWeek.end.raw, 1), { locale }), "yyyy-MM-dd"),
        },
      };
      this.currentWeek = previousWeek;
      this.datepickerDisplayValue.week = `${this.currentWeek.start.name} - ${this.currentWeek.end.name}`;

      this.jumpToWeek(this.currentWeek);
    },
    goToNextWeek() {
      // calculate next quarter
      const nextWeek = {
        start: {
          raw: startOfWeek(addWeeks(this.currentWeek.start.raw, 1), { locale }),
          name: format(startOfWeek(addWeeks(this.currentWeek.start.raw, 1), { locale }), this.dateFormats.week),
          value: format(startOfWeek(addWeeks(this.currentWeek.start.raw, 1), { locale }), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfWeek(addWeeks(this.currentWeek.end.raw, 1), { locale }),
          name: format(endOfWeek(addWeeks(this.currentWeek.end.raw, 1), { locale }), this.dateFormats.week),
          value: format(endOfWeek(addWeeks(this.currentWeek.end.raw, 1), { locale }), "yyyy-MM-dd"),
        },
      };
      this.currentWeek = nextWeek;
      this.datepickerDisplayValue.week = `${this.currentWeek.start.name} - ${this.currentWeek.end.name}`;

      this.jumpToWeek(this.currentWeek);
    },
    goToPreviousDay() {
      // calculate previous quarter
      const previousDay = {
        start: {
          raw: startOfDay(subDays(this.currentDay.start.raw, 1)),
          name: format(startOfDay(subDays(this.currentDay.start.raw, 1)), this.dateFormats.day),
          value: format(startOfDay(subDays(this.currentDay.start.raw, 1)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfDay(subDays(this.currentDay.end.raw, 1)),
          name: format(endOfDay(subDays(this.currentDay.end.raw, 1)), this.dateFormats.day),
          value: format(endOfDay(subDays(this.currentDay.end.raw, 1)), "yyyy-MM-dd"),
        },
      };
      this.currentDay = previousDay;
      this.datepickerDisplayValue.day = this.currentDay.start.name;

      this.jumpToDay(this.currentDay);
    },
    goToNextDay() {
      // calculate next day
      const nextDay = {
        start: {
          raw: startOfDay(addDays(this.currentDay.start.raw, 1)),
          name: format(startOfDay(addDays(this.currentDay.start.raw, 1)), this.dateFormats.day),
          value: format(startOfDay(addDays(this.currentDay.start.raw, 1)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfDay(addDays(this.currentDay.end.raw, 1)),
          name: format(endOfDay(addDays(this.currentDay.end.raw, 1)), this.dateFormats.day),
          value: format(endOfDay(addDays(this.currentDay.end.raw, 1)), "yyyy-MM-dd"),
        },
      };
      this.currentDay = nextDay;
      this.datepickerDisplayValue.day = this.currentDay.start.name;

      this.jumpToDay(this.currentDay);
    },
    gotoToday() {
      // calculate today week
      const todayDay = {
        start: {
          raw: startOfDay(new Date()),
          name: format(startOfDay(new Date()), this.dateFormats.day),
          value: format(startOfDay(new Date()), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfDay(new Date()),
          name: format(endOfDay(new Date()), this.dateFormats.day),
          value: format(endOfDay(new Date()), "yyyy-MM-dd"),
        },
      };
      this.currentDay = todayDay;
      this.datepickerDisplayValue.day = this.currentDay.start.name;

      // calculate today week
      const todayWeek = {
        start: {
          raw: startOfWeek(new Date(), { locale }),
          name: format(startOfWeek(new Date(), { locale }), this.dateFormats.week),
          value: format(startOfWeek(new Date(), { locale }), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfWeek(new Date(), { locale }),
          name: format(endOfWeek(new Date(), { locale }), this.dateFormats.week),
          value: format(endOfWeek(new Date(), { locale }), "yyyy-MM-dd"),
        },
      };
      this.currentWeek = todayWeek;
      this.datepickerDisplayValue.week = `${this.currentWeek.start.name} - ${this.currentWeek.end.name}`;

      // calculate previous month
      const todayMonth = {
        start: {
          raw: startOfMonth(new Date()),
          name: format(startOfMonth(new Date()), this.dateFormats.month),
          value: format(startOfMonth(new Date()), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(new Date()),
          name: format(endOfMonth(new Date()), this.dateFormats.month),
          value: format(endOfMonth(new Date()), "yyyy-MM-dd"),
        },
      };
      this.currentMonth = todayMonth;
      this.datepickerDisplayValue.month = `${this.currentMonth.start.name} - ${this.currentMonth.end.name}`;

      // calculate today quarter
      const todayQuarter = {
        start: {
          raw: startOfQuarter(new Date()),
          name: format(startOfQuarter(new Date()), "MMM, yyyy"),
          value: format(startOfQuarter(new Date()), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfQuarter(new Date()),
          name: format(endOfQuarter(new Date()), "MMM, yyyy"),
          value: format(endOfQuarter(new Date()), "yyyy-MM-dd"),
        },
      };
      this.currentQuarter = todayQuarter;
      this.datepickerDisplayValue.quarter = `${this.currentQuarter.start.name} - ${this.currentQuarter.end.name}`;

      // calculate today six months
      const todaySixMonths = {
        start: {
          raw:
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
            isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? startOfQuarter(new Date())
              : startOfQuarter(subMonths(new Date(), 3)),
          name: format(
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
              isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? startOfQuarter(new Date())
              : startOfQuarter(subMonths(new Date(), 3)),
            "MMM, yyyy"
          ),
          value: format(
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
              isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? startOfQuarter(new Date())
              : startOfQuarter(subMonths(new Date(), 3)),
            "yyyy-MM-dd"
          ),
        },
        end: {
          raw:
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
            isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? endOfQuarter(addMonths(new Date(), 3))
              : endOfQuarter(new Date()),
          name: format(
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
              isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? endOfQuarter(addMonths(new Date(), 3))
              : endOfQuarter(new Date()),
            "MMM, yyyy"
          ),
          value: format(
            isEqual(startOfQuarter(new Date()), startOfYear(new Date())) ||
              isEqual(startOfQuarter(subMonths(new Date(), 6)), startOfYear(new Date()))
              ? endOfQuarter(addMonths(new Date(), 3))
              : endOfQuarter(new Date()),
            "yyyy-MM-dd"
          ),
        },
      };
      this.currentSixMonths = todaySixMonths;
      this.datepickerDisplayValue.sixMonths = `${this.currentSixMonths.start.name} - ${this.currentSixMonths.end.name}`;

      if (this.currentView === "month") {
        this.jumpToMonth(this.currentMonth);
      } else if (this.currentView === "day") {
        this.jumpToDay(this.currentDay);
      } else if (this.currentView === "week") {
        this.jumpToWeek(this.currentWeek);
      } else if (this.currentView === "quarter") {
        this.jumpToQuarter(this.currentQuarter);
      } else if (this.currentView === "sixMonths") {
        this.jumpToSixMonths(this.currentSixMonths);
      }
    },

    showFilterPanel() {
      if (this.filterPanelInstance) {
        this.filterPanelInstance.show();
        return;
      }
      this.filterPanelInstance.promise.then((result) => {});
    },
    filterTitle() {
      this.$root.$emit("calendarFilterChange", this.filter);
    },
    onCalendarFilterChange(filterPanelValues) {
      // Capture the changes and filter the calendar
      this.$log.debug(
        "Calendar - onCalendarFilterChange - Filter Panel values",
        JSON.parse(JSON.stringify({ ...filterPanelValues }))
      );

      // save filter panel values
      this.filterPanelValues = filterPanelValues;
      if (!this.user && !this.filter.company) {
        this.$log.debug("onCalendarFilterChange - NO COMPANY, skipping", {
          filterPanelValues: this.filterPanelValues,
          filter: this.filter,
          user: this.user,
        });
        return false;
      }

      // Define defaults and apply them for now
      const defaultVariables = {
        company: { id: { equals: this.user ? this.user.company.id : this.filter.company.id } },
        startDate: this.filterStartDate,
        brief: {
          title: {
            contains: this.filter.brief.title.contains,
            mode: "insensitive",
          },
        },
        status: {
          name: {
            in: ["approved", "delivered"],
          },
        },
      };
      const apolloFilters = { ...defaultVariables };
      // if (this.$apollo.queries.activities && !this.$apollo.queries.activities.loading) {
      //   this.$apollo.queries.activities.setVariables({
      //     where: this.formatDatesForApi({ ...apolloFilters }),
      //   });
      // }

      // Walk the filter panel and populate the actual filter
      // const dynamicPropertyWhere = {}; // Unimplemented
      const isExternal = { external: false, internal: false };
      if (filterPanelValues) {
        Object.keys(filterPanelValues).forEach((key) => {
          const filterPanelValue = filterPanelValues[key];
          switch (key) {
            case "tags": {
              const tagIds = filterPanelValue.map((tag) => tag.id);
              if (tagIds.length > 0) apolloFilters.brief.tags = { some: { id: { in: tagIds } } };
              break;
            }
            case "locations": {
              const tagIds = filterPanelValue.map((tag) => tag.id);
              if (tagIds.length > 0) apolloFilters.locations = { some: { id: { in: tagIds } } };
              break;
            }
            case "spokespersons": {
              const spokespersonIds = filterPanelValue.map((spokesperson) => spokesperson.id);
              if (spokespersonIds.length > 0) apolloFilters.spokespersons = { some: { id: { in: spokespersonIds } } };
              break;
            }
            case "channels": {
              const channelIds = filterPanelValue.map((channel) => channel.id);
              if (channelIds.length > 0) apolloFilters.channel = { id: { in: channelIds } };
              break;
            }
            case "activityStatuses": {
              const activityStatuses = filterPanelValue.map((status) => status.id);
              if (activityStatuses.length > 0) apolloFilters.status = { id: { in: activityStatuses } };
              break;
            }
            case "isExternal": {
              if (filterPanelValue === true) isExternal.external = true;
              break;
            }
            case "isInternal": {
              if (filterPanelValue === true) isExternal.internal = true;
              break;
            }
            case "owners": {
              const owners = filterPanelValue.map((owner) => owner.id);
              if (owners.length > 0) apolloFilters.owner = { id: { in: owners } };
              break;
            }
            default: {
              // These are all the brief properties. This is untested and unimplemented
              /*
              const dynamicPropertyIds = dynamicPropertyWhere.properties.some
                ? dynamicPropertyWhere.properties.some.multipleValues.some.id.in
                : [];
              filterPanelValue.forEach((s) => {
                dynamicPropertyIds.push(s.id);
              });
              if (dynamicPropertyIds.length > 0)
                apolloFilters.brief.properties.some = dynamicPropertyWhere.properties.some = {
                  multipleValues: {
                    some: {
                      id: {
                        in: dynamicPropertyIds,
                      },
                    },
                  },
                };
                */
              break;
            }
          }
        });
      }

      // Handle internal/external filtering
      if (isExternal.external === isExternal.internal) {
        // If both or none are checked, no need to include the filter
        delete apolloFilters.isExternal;
      } else if (isExternal.external && !isExternal.internal) {
        apolloFilters.isExternal = true;
      } else {
        apolloFilters.isExternal = false;
      }

      this.$log.debug("Calendar - Filtering", {
        where: this.formatDatesForApi({ ...apolloFilters }),
      });
      // Update variables and execute query
      if (this.$apollo.queries.activities && !this.$apollo.queries.activities.loading) {
        this.$apollo.queries.activities.setVariables({
          where: this.formatDatesForApi({ ...apolloFilters }),
        });
        this.$apollo.queries.activities.refetch();
      }
    },

    printPage() {
      document.querySelector("#navbar").classList.add("d-none");
      document.querySelector("#calendartopbar").classList.add("d-none");
      document.querySelector("#todayButton").classList.add("d-none");
      document.querySelector("#navButtons").classList.add("d-none");
      document.querySelector("#printButton").classList.add("d-none");
      document.querySelector("#printheader").classList.remove("d-none");
      window.print();
      document.querySelector("#navbar").classList.remove("d-none");
      document.querySelector("#calendartopbar").classList.remove("d-none");
      document.querySelector("#todayButton").classList.remove("d-none");
      document.querySelector("#navButtons").classList.remove("d-none");
      document.querySelector("#printButton").classList.remove("d-none");
      document.querySelector("#printheader").classList.add("d-none");
    },
    formatDatesForApi(filter) {
      if (!filter) return undefined;
      // this.$log.debug("Formatting Dates for ", filter);
      // Replace dates with ISO strings for the API
      // NOTE: for some reason the date string is turning into ISO, so use parseISO which can
      // handle both ISO date and ISO datetime (datetime being what the API needs)
      const isoFilter = filter;
      if (filter.startDate) {
        Object.keys(filter.startDate).forEach((key) => {
          isoFilter.startDate[key] = (
            " " + format(parseISO(filter.startDate[key]), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          ).slice(1);
        });
      }
      if (filter.endDate) {
        Object.keys(filter.endDate).forEach((key) => {
          isoFilter.endDate[key] = (" " + format(parseISO(filter.endDate[key]), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")).slice(
            1
          );
        });
      }
      if (filter.createdAt) {
        Object.keys(filter.createdAt).forEach((key) => {
          isoFilter.createdAt[key] = (
            " " + format(parseISO(filter.createdAt[key]), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          ).slice(1);
        });
      }
      if (filter.updatedAt) {
        Object.keys(filter.updatedAt).forEach((key) => {
          isoFilter.updatedAt[key] = (
            " " + format(parseISO(filter.updatedAt[key]), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          ).slice(1);
        });
      }
      // this.$log.debug("Formatting Dates result: ", JSON.stringify(isoFilter));
      return isoFilter;
    },

    reorganiseActivities(activities = null) {
      if (!activities) {
        activities = this.activities;
      }
      const result = [];
      let res = null;
      this.organizedActivities = [];

      this.$log.debug("Reorganising activities", activities.length);

      // Set left column to the items selected in the dropdown (channels, spokesperson, team member, etc)
      this.calendarResources = this[this.activitiesGroupBy.id];

      // handle tags
      if (this.activitiesGroupBy.id === "tags") {
        activities.forEach((activity) => {
          activity.extendedProps.brief.tags.forEach((tag) => {
            const newActivity = { ...activity };

            newActivity.resourceId = tag.id;
            res = tag ? find(this.calendarResources, { id: tag.id }) : null;
            if (res && res.events) {
              res.events.push(this.$root.generateUUID());
            } else if (res) {
              res.events = [];
              res.events.push(this.$root.generateUUID());
            }

            result.push(newActivity);
          });
        });
      }
      // Single
      else {
        activities.forEach((activity) => {
          // Group activities based on dropdown value
          switch (this.activitiesGroupBy.id) {
            case "channels":
              activity.resourceId = activity.channel ? activity.channel.id : "nochannel";

              res = activity.channel ? find(this.calendarResources, { id: activity.channel.id }) : null;
              if (res && res.events) {
                res.events.push(activity.id);
              } else if (res) {
                res.events = [];
                res.events.push(activity.id);
              }
              result.push(activity);
              break;

            case "activityStatuses":
              activity.resourceId = activity.status.id;

              res = activity.status ? find(this.calendarResources, { id: activity.status.id }) : null;
              if (res && res.events) {
                res.events.push(activity.id);
              } else if (res) {
                res.events = [];
                res.events.push(activity.id);
              }
              result.push(activity);
              break;

            case "spokespersons":
              activity.resourceId = activity.extendedProps.spokesperson ? activity.extendedProps.spokesperson.id : null;

              res = activity.extendedProps.spokesperson
                ? find(this.calendarResources, {
                    id: activity.extendedProps.spokesperson.id,
                  })
                : null;
              if (res && res.events) {
                res.events.push(activity.id);
              } else if (res) {
                res.events = [];
                res.events.push(activity.id);
              }
              result.push(activity);
              break;

            case "teamMembers":
              activity.resourceId = activity.extendedProps.owner ? activity.extendedProps.owner.id : null;

              res = activity.extendedProps.owner
                ? find(this.calendarResources, {
                    id: activity.extendedProps.owner.id,
                  })
                : null;
              if (res && res.events) {
                res.events.push(activity.id);
              } else if (res) {
                res.events = [];
                res.events.push(activity.id);
              }
              result.push(activity);
              break;

            default:
              break;
          }
        });
      }

      // Remove empty
      this.calendarResources = this.calendarResources.filter(
        (resource) => resource.events && resource.events.length > 0
      );

      return result;
    },
    datepickerGo(type = "day", event = null) {
      if (type === "day") {
        const datepickerRawDate = new Date(this.datepickerDate.startDate);
        // calculate date
        const dateToJump = {
          start: {
            raw: startOfDay(datepickerRawDate),
            name: format(startOfDay(datepickerRawDate), this.dateFormats.day),
            value: format(startOfDay(datepickerRawDate), "yyyy-MM-dd"),
          },
          end: {
            raw: endOfDay(datepickerRawDate),
            name: format(endOfDay(datepickerRawDate), this.dateFormats.day),
            value: format(endOfDay(datepickerRawDate), "yyyy-MM-dd"),
          },
        };
        this.currentDay = dateToJump;
        this.datepickerDisplayValue.day = this.currentDay.start.name;

        this.jumpToDay(this.currentDay);
      } else if (type === "week") {
        const datepickerRawDate = new Date(this.datepickerDate.startDate);
        // calculate date
        const dateToJump = {
          start: {
            raw: datepickerRawDate,
            name: format(datepickerRawDate, this.dateFormats.week),
            value: format(datepickerRawDate, "yyyy-MM-dd"),
          },
          end: {
            raw: addDays(datepickerRawDate, 6),
            name: format(addDays(datepickerRawDate, 6), this.dateFormats.week),
            value: format(addDays(datepickerRawDate, 6), "yyyy-MM-dd"),
          },
        };
        this.currentWeek = dateToJump;
        this.datepickerDisplayValue.week = `${this.currentWeek.start.name} - ${this.currentWeek.end.name}`;

        this.jumpToWeek(this.currentWeek);
      } else if (type === "month") {
        const datepickerRawDate = new Date(this.datepickerDate.startDate);
        // calculate date
        const dateToJump = {
          start: {
            raw: startOfMonth(datepickerRawDate),
            name: format(startOfMonth(datepickerRawDate), this.dateFormats.month),
            value: format(startOfMonth(datepickerRawDate), "yyyy-MM-dd"),
          },
          end: {
            raw: endOfMonth(datepickerRawDate),
            name: format(endOfMonth(datepickerRawDate), this.dateFormats.month),
            value: format(endOfMonth(datepickerRawDate), "yyyy-MM-dd"),
          },
        };
        this.currentMonth = dateToJump;
        this.datepickerDisplayValue.month = `${this.currentMonth.start.name} - ${this.currentMonth.end.name}`;

        this.jumpToMonth(this.currentMonth);
      } else if (type === "quarter") {
        const startMonth = new Date(event.target.value);
        // calculate date
        const dateToJump = {
          start: {
            raw: startOfMonth(startMonth),
            name: format(startOfMonth(startMonth), this.dateFormats.quarter),
            value: format(startOfMonth(startMonth), "yyyy-MM-dd"),
          },
          end: {
            raw: endOfMonth(addMonths(startOfMonth(startMonth), 2)),
            name: format(endOfMonth(addMonths(startOfMonth(startMonth), 2)), this.dateFormats.quarter),
            value: format(endOfMonth(addMonths(startOfMonth(startMonth), 2)), "yyyy-MM-dd"),
          },
        };
        this.currentQuarter = dateToJump;
        this.datepickerDisplayValue.quarter = `${this.currentQuarter.start.name} - ${this.currentQuarter.end.name}`;

        this.jumpToQuarter(this.currentQuarter);
      } else if (type === "sixMonths") {
        const startMonth = new Date(event.target.value);

        // calculate date
        const dateToJump = {
          start: {
            raw: startOfMonth(startMonth),
            name: format(startOfMonth(startMonth), this.dateFormats.sixMonths),
            value: format(startOfMonth(startMonth), "yyyy-MM-dd"),
          },
          end: {
            raw: endOfMonth(addMonths(startOfMonth(startMonth), 5)),
            name: format(endOfMonth(addMonths(startOfMonth(startMonth), 5)), this.dateFormats.sixMonths),
            value: format(endOfMonth(addMonths(startOfMonth(startMonth), 5)), "yyyy-MM-dd"),
          },
        };
        this.currentSixMonths = dateToJump;
        this.datepickerDisplayValue.sixMonths = `${this.currentSixMonths.start.name} - ${this.currentSixMonths.end.name}`;

        this.jumpToSixMonths(this.currentSixMonths);
      }
    },

    async jumpToMonth(month) {
      this.$log.debug("Jumping to Month: ", month);
      const m = month.target || month;
      const curMonth = {
        start: {
          raw: startOfMonth(new Date(m.start.raw)),
          name: format(startOfMonth(new Date(m.start.raw)), this.dateFormats.month),
          value: format(startOfMonth(new Date(m.start.raw)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(new Date(m.end.raw)),
          name: format(endOfMonth(new Date(m.end.raw)), this.dateFormats.month),
          value: format(endOfMonth(new Date(m.end.raw)), "yyyy-MM-dd"),
        },
      };

      // Set filters
      this.currentMonth = curMonth;
      this.datepickerDisplayValue.month = `${this.currentMonth.start.name} - ${this.currentMonth.end.name}`;
      this.datepickerDate = { startDate: this.currentMonth.start.raw, endDate: this.currentMonth.start.raw };

      // Load events for the specified month
      this.onCalendarFilterChange(this.filterPanelValues);
    },
    async jumpToQuarter(quarter) {
      const q = quarter.target || quarter;
      const curMonth = {
        start: {
          raw: startOfMonth(new Date(q.start.raw)),
          name: format(startOfMonth(new Date(q.start.raw)), this.dateFormats.quarter),
          value: format(startOfMonth(new Date(q.start.raw)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(new Date(q.end.raw)),
          name: format(endOfMonth(new Date(q.end.raw)), this.dateFormats.quarter),
          value: format(endOfMonth(new Date(q.end.raw)), "yyyy-MM-dd"),
        },
      };

      // Set filters
      this.currentQuarter = curMonth;
      this.datepickerDisplayValue.quarter = `${this.currentQuarter.start.name} - ${this.currentQuarter.end.name}`;
      this.datepickerDate = { startDate: this.currentQuarter.start.raw, endDate: this.currentQuarter.start.raw };

      // Load events for the specified month
      if (this.filterPanelValues) {
        this.onCalendarFilterChange(this.filterPanelValues);
      } else {
        await this.$apollo.queries.activities.refetch();
      }
    },
    async jumpToSixMonths(sixMonths) {
      const q = sixMonths.target || sixMonths;
      const curMonth = {
        start: {
          raw: startOfMonth(new Date(q.start.raw)),
          name: format(startOfMonth(new Date(q.start.raw)), this.dateFormats.sixMonths),
          value: format(startOfMonth(new Date(q.start.raw)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfMonth(new Date(q.end.raw)),
          name: format(endOfMonth(new Date(q.end.raw)), this.dateFormats.sixMonths),
          value: format(endOfMonth(new Date(q.end.raw)), "yyyy-MM-dd"),
        },
      };

      // Set filters
      this.currentSixMonths = curMonth;
      this.datepickerDisplayValue.sixMonths = `${this.currentSixMonths.start.name} - ${this.currentSixMonths.end.name}`;
      this.datepickerDate = { startDate: this.currentSixMonths.start.raw, endDate: this.currentSixMonths.start.raw };

      // Load events for the specified month
      if (this.filterPanelValues) {
        this.onCalendarFilterChange(this.filterPanelValues);
      } else {
        await this.$apollo.queries.activities.refetch();
      }
    },
    async jumpToWeek(week) {
      const w = week.target || week;
      const currWeek = {
        start: {
          raw: startOfWeek(new Date(w.start.raw), { locale }),
          name: format(startOfWeek(new Date(w.start.raw), { locale }), this.dateFormats.week),
          value: format(startOfWeek(new Date(w.start.raw), { locale }), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfWeek(new Date(w.end.raw), { locale }),
          name: format(endOfWeek(new Date(w.end.raw), { locale }), this.dateFormats.week),
          value: format(endOfWeek(new Date(w.end.raw), { locale }), "yyyy-MM-dd"),
        },
      };

      // Set filters
      this.currentWeek = currWeek;
      this.datepickerDisplayValue.week = `${this.currentWeek.start.name} - ${this.currentWeek.end.name}`;
      this.datepickerDate = { startDate: this.currentWeek.start.raw, endDate: this.currentWeek.start.raw };

      // Load events for the specified month
      if (this.filterPanelValues) {
        this.onCalendarFilterChange(this.filterPanelValues);
      } else {
        await this.$apollo.queries.activities.refetch();
      }
    },
    async jumpToDay(day) {
      const d = day.target || day;
      const currDay = {
        start: {
          raw: startOfDay(new Date(d.start.raw)),
          name: format(startOfDay(new Date(d.start.raw)), this.dateFormats.day),
          value: format(startOfDay(new Date(d.start.raw)), "yyyy-MM-dd"),
        },
        end: {
          raw: endOfDay(new Date(d.end.raw)),
          name: format(endOfDay(new Date(d.end.raw)), this.dateFormats.day),
          value: format(endOfDay(new Date(d.end.raw)), "yyyy-MM-dd"),
        },
      };

      // Set filters
      this.currentDay = currDay;
      this.datepickerDisplayValue.day = `${this.currentDay.start.name}`;
      this.datepickerDate = { startDate: this.currentDay.start.raw, endDate: this.currentDay.start.raw };

      // Load events for the specified month
      if (this.filterPanelValues) {
        this.onCalendarFilterChange(this.filterPanelValues);
      } else {
        await this.$apollo.queries.activities.refetch();
      }
    },

    switchView(view, goToToday = true) {
      this.currentView = view;

      if (goToToday === true) this.gotoToday();
    },
  },
};
</script>
