<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp], // Creates a props named chartData and adds vue watch
  props: ["options"],
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
